.react-tabs {
  -webkit-tap-highlight-color: transparent;
  
  &__tab-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    margin-bottom: 0;
  }

  &__tab {
    font-size: 14pt;
    flex-grow: 1;
    text-align: center;
    border: none;
    background-color: lightgray;
    list-style: none;
    padding: 14px 16px;
    cursor: pointer;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    --bg-color: red;

    &:hover {
      background-color: #777777;
    }

    &--selected, &--selected:hover {
      background-color: var(--bg-color);
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
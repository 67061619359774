@mixin show-caption {
  transform: translateY(-100%);
  /* make it less obvious if isn't actually disappearing under the image */
  /* (when text is popping in/out */
  opacity: 1;  
}

// always show caption if in the modal, otherwise show only if on large screens
.react-responsive-modal-modal .image:hover .image__caption {
  @include show-caption;
}

.image {
  margin: 0;
  overflow: hidden;
  // parent must be relative for absolute to be relative to parent
  position: relative;  
  // account for padding in sizing
  box-sizing: border-box;
  padding: 0 1em;
  // make sure caption is right underneath image
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .lazyload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  img, video {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  @media(min-width: 1024px) {
    &:hover &__caption {
      @include show-caption;
    }
  }

  &__caption {
    opacity: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    transition: 0.2s;
  }
}

.image-wrapper {
  height: 100%;
  width: 100%;
}
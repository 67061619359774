@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

body {
  font-weight: 400;
  font-family: Nunito;
  font-style: normal;
}

.portfolio {
  display: flex;
  height: 100vh;
  flex-direction: column;

  .portfolio__header {
    text-align: center;
    font-weight: 400;
  }

  .react-tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .react-tabs__tab-panel {
    flex-grow: 1;
  }
}

@mixin tab-panel {
  min-height: 100%;
  padding-left: 12%;
  padding-right: 12%;
}

@mixin full-background($bg_url) {
  background-image: url($bg_url);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.tab__resume {
  @include tab-panel;
  @include full-background('../../images/background_resume.svg');
  display: flex;
  flex-direction: column;
}

.tab__contact {
  @include tab-panel;
  @include full-background('../../images/background_contact.svg');
  h2 {
    margin: 0;
    padding-top: 1em;
    text-align: center;
    font-weight: 400;
  }
}

.tab__engineering {
  @include tab-panel;
  @include full-background('../../images/background_home.svg');
  h2, h3{
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: 400;
  }

  .card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    gap: 2em;
    grid-template-areas: 
    "coasters coasters coasters coasters"
    "aprovecho aprovecho aprovecho aprovecho"
    "paintingdoor paintingdoor paintingdoor paintingdoor"
    "timer timer starplates starplates"
    "foldingwalls foldingwalls foldingwalls foldingwalls"
    "boat boat boat boat"
    "e80 e80 e80 e80"
    "hiddengears hiddengears hiddengears hiddengears"
    "walls walls walls walls"
    "solarcart solarcart solarcart solarcart"
    "clinic clinic clinic clinic"
    "sculpture sculpture cnc-puzzle cnc-puzzle"
    "chest chest chest chest"
    "ddr ddr ddr ddr"
    "clock clock clock clock"
    "laptop-holder laptop-holder robot robot"
    "light-switch light-switch light-switch light-switch"
    "trebuchet trebuchet pencil-holder pencil-holder"
    "hh hh hh hh"
    "ferris-wheel ferris-wheel ferris-wheel ferris-wheel"
    "eiffel-tower eiffel-tower nodes nodes";

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-areas:
      "coasters"
      "aprovecho"
      "paintingdoor"
      "timer"
      "starplates"
      "foldingwalls"
      "boat"
      "e80"
      "solarcart"
      "clinic"
      "sculpture"
      "organizer"
      "hod"
      "cnc-puzzle"
      "chest"
      "embroidery"
      "tote-bag"
      "string-art"
      "ddr"
      "clock"
      "laptop-holder"
      "hotdog"
      "light-switch"
      "flowers"
      "robot"
      "heart"
      "pencil-holder"
      "hh"
      "undertale"
      "trebuchet"
      "ferris-wheel"
      "eiffel-tower"
      "nodes";
    }
  }
}

.tab__art {
  @include tab-panel;
  @include full-background('../../images/background_home.svg');
  h2, h3{
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    font-weight: 400;
  }

  .card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    gap: 2em;
    grid-template-areas: 
    "foldingwalls foldingwalls foldingwalls foldingwalls"
    "boat boat boat boat"
    "organizer organizer organizer hod"
    "tote-bag tote-bag embroidery embroidery"
    "string-art string-art string-art string-art"
    "flowers flowers flowers hotdog"
    "heart heart heart undertale"
    "hh hh hh hh"
    "ferris-wheel ferris-wheel ferris-wheel ferris-wheel"
    "eiffel-tower eiffel-tower eiffel-tower eiffel-tower";

    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-areas:
      "foldingwalls"
      "boat"
      "clinic"
      "sculpture"
      "organizer"
      "hod"
      "cnc-puzzle"
      "chest"
      "embroidery"
      "tote-bag"
      "string-art"
      "ddr"
      "clock"
      "laptop-holder"
      "hotdog"
      "light-switch"
      "flowers"
      "robot"
      "heart"
      "pencil-holder"
      "hh"
      "undertale"
      "trebuchet"
      "ferris-wheel"
      "eiffel-tower"
      "nodes";
    }
  }
}
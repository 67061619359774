#chest-page {
  .home-button {
    /* make it float on top left */
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    &:visited {
      color: #fff;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    display: block; /* remove extra space below image */
    border-radius: 30px;
    margin: 0 auto;
  }

  h1,h2 {
    text-align: center;
    color: white;
  }

  p {
    margin: 0px;
  }

  tbody {
    width: 100%;
  }

  table, th, td {
    border: 3px solid white;
    border-collapse: collapse;
    padding: 8px;
  }

  table {
    border-radius: 10px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  th, td {
    background-color: rgb(194, 242, 235);
  }

  /* make rounded border for table */

  thead > tr:first-child > :first-child {
    border-top-left-radius: 20px;
  }

  thead > tr:first-child > :last-child {
    border-top-right-radius: 20px;
  }

  tbody > tr:last-child > :first-child {
    border-bottom-left-radius: 20px;
  }

  tbody > tr:last-child > :last-child {
    border-bottom-right-radius: 20px;
  }

  .flexgrid-container {
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin: auto;
    border-radius: 30px;
    background: white;
    padding:30px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    @media(max-width: 768px) {
      justify-content: center;
    }
  }

  .row-align-stretch {
    align-items: stretch;
  }

  .flex-item-1 {
    flex-basis: 100%;
  }

  .flex-item-2 {
    flex-basis: 47%;  

    @media(max-width: 768px) {
      margin-top: 10px;
      flex-basis: 100%;
    }
  }

  .flex-item-3 {
    flex-basis: 30%;

    @media(max-width: 768px) {
      margin-top: 10px;
      flex-basis: 100%;
    }
  }
}